import React from 'react';
import { Box, Flex } from '@fuji-ui/core';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import wave from '../images/wave.svg';
import waveBlue from '../images/wave-blue.svg';
import waveEnd from '../images/wave-end.svg';
import waveEndBlue from '../images/wave-end-blue.svg';

export const FeatureBlock = (props) => {
  return (
    <Flex
      as="section"
      alignItems={['stretch', null, 'flex-start', null]}
      {...props}
    />
  );
};

export const CenterBlock = (props) => {
  return (
    <Flex
      as="section"
      alignItems={['stretch', null, 'center', null]}
      {...props}
    />
  );
};

export const SmallScreenOnly = (props) => {
  return <Box display={['block', 'none', null, null]} {...props} />;
};

export const ExcludeSmallScreen = (props) => {
  return <Box display={['none', 'block', null, null]} {...props} />;
};

export const WaveBlock = styled(Box)`
  background: url("${(props) =>
    props.blueStart
      ? waveEndBlue
      : waveEnd}") center top -1px/100% 100px no-repeat, url("${(props) =>
  props.endBlue ? waveBlue : wave}") center bottom -1px/100% 100px no-repeat ;
  background-color: ${(props) =>
    props.waveBgColor || themeGet('colors.background')};
`;

export const WaveStartBlock = styled(Box)`
  background: url("${(props) =>
    props.blue ? waveEndBlue : waveEnd}") center top -1px/100% 100px no-repeat;
  background-color: ${(props) =>
    props.waveBgColor || themeGet('colors.background')};
`;

export const WaveEndBlock = styled(Box)`
  background: url("${(props) =>
    props.blue ? waveBlue : wave}") center bottom -1px/100% 100px no-repeat;
  background-color: ${(props) =>
    props.waveBgColor || themeGet('colors.background')};
`;
