import React from 'react';
import { Typography as T, Box } from '@fuji-ui/core';
import SEO from '../components/seo';
import { Wrapper } from '../components/layout';
import { SmallScreenOnly, ExcludeSmallScreen } from '../components/block';
// import ImgTeam from '../images/team_illustrate.jpg';
import ImgTeamCollaborate from '../images/team-collaborate.svg';
import { StaticImage } from 'gatsby-plugin-image';
const AboutUsPage = (props) => {
  return (
    <>
      <SEO lang="en" title="The team behind Teamwork App" />
      <Box>
        <Box flex={1} textAlign="center">
          <SmallScreenOnly mx="-10px" maxWidth="100vw">
            {/* <img src={ImgTeam} width="100%" /> */}
            <StaticImage
              src="../images/team_illustrate.jpg"
              alt="The team behind Teamwork App"
              style={{
                width: '95%',
                height: '95%',
              }}
            />
          </SmallScreenOnly>
        </Box>
        <Wrapper>
          <Box
            as="section"
            m="auto"
            mt="l"
            textAlign="center"
            maxWidth={[null, 900, 900, null]}
          >
            <T.H1>
              Devoted to make teamwork <br />
              more pleasant.
            </T.H1>
            <T.P>
              The team behind Talkbox created the world's first voice messenger
              app in 2011 after a year-long research and development. With the
              increasing demand of a versatile messenger for enterprise, Talkbox
              is elevated from a 13.3 million user mass messenger to an
              enterprise communication solution.
            </T.P>
          </Box>
          <ExcludeSmallScreen>
            {/* <img src={ImgTeam} width="100%" /> */}
            <StaticImage
              src="../images/team_illustrate.jpg"
              alt="The team behind Teamwork App"
              imgStyle={{ width: '100%' }}
            />
          </ExcludeSmallScreen>
        </Wrapper>
      </Box>
      <Box mt="xl">
        <Wrapper>
          <Box
            as="section"
            m="auto"
            mt="l"
            textAlign="center"
            maxWidth={[null, 900, 900, null]}
          >
            <T.H1>
              We use Teamwork App to build and support Teamwork App everyday.
            </T.H1>
            <T.P>
              A while back we suffered from lack of team communication tool
              until we build Teamwork App. It boosts our work satisfaction level
              to the highest - we love using our products and so we think others
              may do as well.
            </T.P>
            <Box>
              <img src={ImgTeamCollaborate} width="100%" />
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};
export default AboutUsPage;
